<template>
	<div class="">
        <div :class="{'has-red-border': param.new_one}" class="paaram is-flex mb-1 has-border" style="align-items: center" v-if="type == 2">
            <small class="has-text-grey-light ml-1 has-text-centered" style="width: 18px">{{index + 1}}</small> 
            <div @click.prevent="$parent.$parent.open_modal(relation, parameters_list, type, form_mode, param)" v-if="form_fields.length > 0" 
				style="width:130px; max-width: 130px; font-size:11px;"
				class="has-text-light has-text-weight-bold has-text-centered is-align-self-stretch pt-1 is-cobalt-4" >
                {{form_fields[0].value}}
            </div>
            <div @click.prevent="$parent.$parent.open_modal(relation, parameters_list, type, form_mode, param)"  v-if="param.data" class="has-background-white px-1 is-flex-grow-1 is-clickable" style="width:auto;">
                {{param.data}} 
                <small class="is-italic has-text-grey">
                    {{other_fields}} 
                </small>
            </div>
            <a @click.prevent="$parent.$parent.remove_parameter(param)" class="tag tag-icon mb-0 px-1" >
                <i class="fas fa-trash-alt has-text-danger mx-1"></i>
            </a>
        </div>
        <div :class="{'has-red-border': param.new_one}" class="paaram is-flex ml-2 mb-1 has-border">
            <!-- background-color: #e8f0f5; -->
            <small class="has-text-grey-light mx-2 has-text-centered">{{index + 1}}</small> 
            <a style=" color: #dedede;"
                class="tag is-flex-grow-1 tag-name is-justify-content-flex-start mb-0 pl-1 has-background-white has-text-dark" 
                @click.prevent="$parent.$parent.open_modal(relation, parameters_list, type, form_mode, param)" >
                <div >
                    {{param.name}} 
                </div>
            </a>
            <a v-if="am_allowed('parameter.can_delete')" @click.prevent="$parent.$parent.remove_parameter(param)" class="tag tag-icon parameter_delete mb-0 px-1" :title="$t('delete_supervisor')">
                <i class="fas fa-trash-alt has-text-danger fa-sm mx-1"></i>
            </a>
        </div>
    </div>
</template>

<script>
    import permissionMixin from '@/permissions-mixin.js'
export default {
    mixins: [ permissionMixin ],
    props: ['param', 'relation', 'parameters_list', 'type', 'form_mode', 'index'],
    
    computed:{
        form_fields(){
            if(this.param.form_value){
                if(this.param.form_value instanceof Array) return this.param.form_value
                else return JSON.parse(this.param.form_value)
            } else {
                return []
            }
        },
        other_fields(){
            var a = ''
            this.form_fields.map((ff, index) => {
                if(index > 0 && ff.preview == true){
                    if(ff.type == "date")
                        a += this.$options.filters.customdate(ff.value) + ', '
                    else
                        a += ff.value + ', '
                }
            })
            return a != '' ? a.slice(0, -2) : ''
        }
    },
	watch: {
		"param.new_one": function(val){
			console.log('is new', this.param.new_one, val)
			setTimeout(()=> {
				this.param.new_one = false
			}, 3000)
		}
	}
}
</script>

<style>
.paaram{
	-webkit-transition : border 1500ms ease-out;
	-moz-transition : border 1500ms ease-out;
	-o-transition : border 1500ms ease-out;
	transition : border 1500ms ease-out;
}

.has-red-border{
	border: 1px solid #00e47e;
	animation: blink 1s;
    animation-iteration-count: 20;
	animation: blink .5s step-end infinite alternate;
}

@keyframes blink { 50% {  
box-shadow: rgba(14, 230, 79, 0.15) 0px 30px 60px -12px inset, rgba(94, 255, 143, 0.1) 0px 18px 36px -18px inset;
}  }
</style>
